import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, of } from "rxjs";
import { IApiResponse, IData } from "../models/api.response";
import { UtilService } from "./util-service.service";
import { BaseParams } from "@core/models/base-params";
import { environment } from "src/environments/environment";
import { IApiSendModel } from "@core/models/api-send-model";
import { IBaseMapper } from "@shared/model/mapper-model";
import { IMapperResponse } from "@shared/model/mapper-response-model";

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    baseMapper!: IBaseMapper<any, any, any, any>
    constructor(public http: HttpClient) { }

    getAllWithParams<T, P extends BaseParams>(params: P, url: string): Observable<IMapperResponse<T[]> | undefined> {
        return this.http.get<IApiResponse<IData<T>>>(`${UtilService.generateApiUrl(`${url}`, params)}`,
            { headers: { 'content-type': 'application/json' } })
            .pipe(
                map(res => this.baseMapper.fetchList(res.data ?? {} as IData<T>))
            );
    }

    getItemById<T>(url: string, itemId: string): Observable<T | undefined> {
        return this.http.get<IApiResponse<T>>(`${url}/${itemId}`, { headers: { 'content-type': 'application/json' } })
            .pipe(
                map(res => this.baseMapper.fetchGetById(res.data))
            );
    }

    postItem<T, D>(url: string, item: IApiSendModel<D>): Observable<T | undefined> {
        return this.http.post<IApiResponse<T>>(`${url}`, item, { headers: { 'content-type': 'application/json' } })
            .pipe(
                map(res => {
                    return res.data;
                })
            );
    }

    updateItem<T, D>(url: string, item: IApiSendModel<D>, id: string): Observable<T | undefined> {
        return this.http.put<IApiResponse<T>>(`${url}/${id}`, item, { headers: { 'content-type': 'application/json' } })
            .pipe(
                map(res => {
                    return res.data;
                })
            );
    }

    deleteItem<T>(url: string, id: string): Observable<T | undefined> {
        return this.http.delete<IApiResponse<T>>(`${url}/${id}`, { headers: { 'content-type': 'application/json' } })
            .pipe(
                map(res => {
                    return res.data;
                })
            );
    }
}
