import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  static generateApiUrl<T extends Record<string, any>>(baseUrl: string, filters: T): string {
    let params = new HttpParams();

    for (const [key, value] of Object.entries(filters)) {
      // Check for null, undefined, and empty string
      if (value !== null && value !== undefined && value !== '') {
        if (key == 'pageSize') {
          params = params.set('Paging.Size', value.toString());
        }
        else if (key == 'pageNumber') {
          params = params.set('Paging.Index', value.toString());
        }
        else if (key == 'orders') {
          for (const [orderKey, orderValue] of Object.entries(value)) {
            if (orderValue)
              params = params.set('Orders.' + orderKey, orderValue == 'asc' ? 'Ascending' : 'Descending');
          }
        }
        else {
          params = params.set('filter.' + key, value.toString());
        }
      }
    }

    const queryString = params.toString();
    return queryString ? `${baseUrl}?${queryString}` : baseUrl;
  }
}
